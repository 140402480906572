import { Providers } from "./lib/providers";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { privateRoutes, routes } from "./pages";
import "./i18n";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  checkBitanicaLinkApi,
  fetchUserIp,
  getSettings,
  telegramBotAutoLogin,
  telegramLogin,
  userSession,
} from "./lib/store/actions";
import PrivateRoute from "./components/PrivateRoute";
import "./App.css";
import MainLayout from "./components/Layout/Mainlayout";
import { useNavigate } from "react-router-dom";
import { ToastProvider } from "./context/CustomToast";
import GameEnterRoute from "./components/GameEnterRoute";
import { useIsTelegramWebApp } from "./hooks/useTelegram";
import { GameProvider, useGameContext } from "./context/GameContext";
import { decrypt } from "./helper/secretManager";
import Maintenance from "./pages/Maintenance";
import { isMobile } from "./helper/common";
import { authSlice } from "./lib/store/auth/slice";
import { socket } from "./utils/socket";
import { useWindow } from "./hooks/useWindow";

function App() {
  console.log = function () { };  // Override console.log to a no-op function
  return (
    <ToastProvider>
      <Providers>
        <ToastContainer
          // transition={"Slide"}
          position="top-center"
          hideProgressBar={true}
          closeButton={false}
          limit={1}
          pauseOnFocusLoss={false}
          pauseOnHover={false}
          // autoClose={150000}
        />
        <GameProvider>
          <BrowserRouter>
            <RoutesList />
          </BrowserRouter>
        </GameProvider>
      </Providers>
    </ToastProvider>
  );
}

const RoutesList = () => {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //******************** */
  const timeoutRef = useRef(null); // Use ref to store timeout
  const timeoutDone = useRef(false);
    //******************** */
   
    // useEffect(() => {
    //   // Set a timeout to trigger after 500ms
    //   timeoutRef.current = setTimeout(() => {
    //     timeoutDone.current = true; // Mark timeout as done
    //   }, 500);
  
    //   // Clean up timeout when component unmounts
    //   return () => clearTimeout(timeoutRef.current);
    // }, []);

  const { user, isBitanicaLinked } = useSelector(({ Auth }) => ({
    user: Auth.user,
  }));

  // const { tasksList } = useSelector(({ Tasks }) => ({
  //   tasksList: Tasks.tasksList,
  // }));
  // const {
  //   screenSize: { dynamicHeight },
  // } = useWindow();
  const [sessionLoader, setSessionLoader] = useState(true);
  const [loginLoader, setLoginLoader] = useState(true);
  const { setGame, setGameStart, setGameLoading, gameLoading } =
    useGameContext();
  const maxRetries = 5; // Maximum number of retries
  var retryCount = 0;

  const isTelegramWebApp = useIsTelegramWebApp();
  const userId = user?._id;

  const ativeGameDetail = async (userData) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          (userData?._id && !userData?.currentGame?._id) ||
          !userData?._id ||
          !userData?.currentGame?._id
        ) {
          // setTimeout(() => {
          //   setGameLoading(false);
          //   resolve();  // Resolve since no game to load
          // }, 2000);
          setTimeout(() => {
            setGameLoading(false);
            resolve(); // Resolve since no game to load
          }, 200);
          return;
        }

        setGameLoading(true);
        if (userData?.currentGame?._id) {
          let updatedData = {
            ...userData?.currentGame,
            gameData: [],
            lineData: [],
          };
          // const keys = await socket.emit('getValue', { data: {} });

          // let decoded = updatedData?.gameData
          //   ? await decrypt(userData?.currentGame?.gameData, keys?.ENCRYPT_SECRET_KEY, keys?.ENCRYPT_SECRET_IV)
          //   : [];

          // decoded = decoded ? JSON.parse(decoded) : [];

          updatedData = {
            ...updatedData,
            gameData: userData?.currentGame?.gameData,
            lineData: userData?.currentGame?.lineData,
            winnings: updatedData?.winnings?.length
              ? [...updatedData.winnings]
              : [],
          };

          setGame({
            ...updatedData,
            gameData: updatedData?.gameData?.reverse(),
            lineData: updatedData?.lineData?.reverse(),
          });
          resolve(updatedData);
          setGameLoading(false);
          setGameStart(true);
        } else {
          setGameLoading(false);
          resolve(); // Resolve if no game data
        }
      } catch (err) {
        setGameLoading(false);
        reject(err); // Reject if there is an exception
      }
    });
  };
 
  useEffect(() => {
    dispatch(
      fetchUserIp({
        callBack: (err, data) => {
          if (err) {
            console.error("Error fetching IP:", err);
          } else {
            console.log("Fetched IP:", data);
          }
        },
      })
    );
  },[dispatch])

  useEffect(() => {
    setLoginLoader(true);
    dispatch(
      getSettings({
        callBack: (err, data) => {
          if (err) {
            return;
          }
          if (data?.maintenance?.status) {
            setTimeout(() => {
              setLoginLoader(false);
            }, 2000);
            navigate("/maintenance");
          } else {
            // setTimeout(() => {
            //   setLoginLoader(false);
            // }, 2000)
            telegramWebAppLogin();
          }
        },
      })
    );
  }, [isTelegramWebApp]);

  const telegramWebAppLogin = useCallback(() => {
    if (retryCount > maxRetries) {
      // Stop retries if we've exceeded the maxRetries
      setTimeout(() => {
        setLoginLoader(false);
      }, 6000);
      return;
    }

    try {
      if (
        isTelegramWebApp &&
        window.Telegram.WebApp.initDataUnsafe?.user &&
        isMobile()
      ) {
        const telegramUser = window.Telegram.WebApp.initDataUnsafe.user;
        setLoginLoader(true); // Show loader while trying to log in
        dispatch(authSlice.actions.setLoginBotQrLoader({ type: "botLogin" }));
        dispatch(
          telegramBotAutoLogin({
            data: {
              telegram_id: telegramUser.id,
              telegram_username: telegramUser?.username,
              first_name: telegramUser?.first_name,
              last_name: telegramUser?.last_name,
            },
            callBack: async (err, data) => {
              if (parseInt(err?.status_code) === 406) {
                setLoginLoader(false);
                return navigate("/maintenance");
              }
              if (err) return; //if login error then exit retrying
              // setTimeout(() => {
              setLoginLoader(false);
              // }, 4000)
              await ativeGameDetail(data);
              navigate("/game");
            },
          })
        );
      } else {
        // No user data received, retry login
        retryCount += 1;
        // setLoginLoader(false);
        setTimeout(() => {
          telegramWebAppLogin(); // Retry
        }, 1000); // Retry after 2 seconds
      }
    } catch (err) {
      console.error("Error during Telegram WebApp login", err);
      // setTimeout(() => {
      //   setLoginLoader(false);
      // }, 5000)
    }
  }, [isTelegramWebApp]);

  useEffect(() => {
    // const handleVisibilityChange = () => {
    //   // Check if the window.Telegram.WebApp exists (i.e., page is opened inside Telegram)
    //   if (isTelegramWebApp && window.Telegram?.WebApp) {
    //     const tele = window.Telegram.WebApp; // Initialize the TELEGRAM BOT
    //     if (document.hidden) {
    //       // App is minimized or out of focus
    //       return tele.close(); // Close or minimize the mini-app if needed
    //     }
    //   }
    // }
    if (isTelegramWebApp && window.Telegram?.WebApp) {
      const tele = window.Telegram.WebApp; // Initialize the TELEGRAM BOT
      tele.expand(); // Expands the app on the users' phone to 100% height
    }
    // document.addEventListener('visibilitychange', handleVisibilityChange);

    // return () => {
    //   document.removeEventListener('visibilitychange', handleVisibilityChange);
    // };
  }, [isTelegramWebApp]);

  const checkAuth = async () => {
    try {
      setSessionLoader(true);
      await dispatch(
        userSession({
          callBack: async (err, userData) => {
            if (err) {
              setTimeout(() => {
                setSessionLoader(false);
              }, 2000);
              if (!["/login", "/"].includes(window.location.pathname)) {
                document.cookie = "";
                setTimeout(() => {
                  navigate("/login");
                }, 2000);
              }
            } else {
              await ativeGameDetail(userData);
              setTimeout(() => {
                setSessionLoader(false);
              }, 2000);
            }
          },
        })
      ); // Dispatch the action to check authentication
    } catch (error) {
      setTimeout(() => {
        setSessionLoader(false);
        setGameLoading(false);
      }, 2000);
    } finally {
      setTimeout(() => {
        setSessionLoader(false);
      }, 2000);
      // setGameLoading(false);
    }
  };

  useEffect(() => {
    const telegramUser = window.Telegram.WebApp.initDataUnsafe.user;
    const hash = window.location.hash;

    if (!userId && !isTelegramWebApp && !telegramUser && !hash) {
      setTimeout(() => {
        checkAuth();
      }, 300);
    } else {
      setTimeout(() => {
        setSessionLoader(false);
      }, 2000);
    }
  }, [userId, isTelegramWebApp]);

  // useEffect(() => {
  //   // Disable scrolling on the body
  //   document.body.style.overflow = "hidden";

  //   // Function to handle touchmove event
  //   const preventScroll = (event) => {
  //     // Check if the event's target is within a scrollable element
  //     const isScrollable = event.target.closest('.allow-scroll');
  //     const noMini = event.target.closest('.no-minimize')
  //     if (!isScrollable || noMini) {
  //       // Prevent scrolling if not within a scrollable element
  //       event.preventDefault();
  //     }
  //   };

  //   // Add touch event listeners to the document
  //   document.addEventListener('touchmove', preventScroll, { passive: false });

  //   return () => {
  //     // Cleanup on component unmount
  //     document.body.style.overflow = "";
  //     document.removeEventListener('touchmove', preventScroll);
  //   };
  // }, [pathname]);

  useEffect(() => {
    const preventScroll = (event) => {
      // Check if the event's target is within a scrollable element
      const isScrollable = event.target.closest(".allow-scroll");
      const noMini = event.target.closest(".no-minimize");

      console.log("Is scrollable:", isScrollable ? true : false);
      console.log("Is no-minimize:", noMini ? true : false);

      if (!isScrollable || noMini) {
        console.log("Preventing default scroll behavior :::::::::");
        event.preventDefault();
      }
    };
    // Delay to allow the DOM to stabilize if necessary
    const timeout = setTimeout(() => {
      console.log("Setting body overflow to hidden :::::::::");
      document.body.style.overflow = "hidden";

      console.log("Adding touchmove event listener :::::::::");
      document.addEventListener("touchmove", preventScroll, { passive: false });
    }, 100); // Adjust delay as needed

    return () => {
      console.log("Cleaning up :::::::::");
      document.body.style.overflow = "";
      console.log("Removing touchmove event listener :::::::::");
      document.removeEventListener("touchmove", preventScroll);
      clearTimeout(timeout);
    };
  }, []);

  // useEffect(() => {
  //   if (user && !isBitanicaLinked) {
  //     dispatch(checkBitanicaLinkApi({}));
  //   }
  // }, [user, pathname, isBitanicaLinked]);

  useEffect(() => {
    if (pathname.includes("/login")) {
      // Get the hash part of the URL
      const hash = window.location.hash;

      // Extract the tgAuthResult if it's present in the hash
      const match = hash.match(/tgAuthResult=([^&]*)/);
      console.log("matchmatchmatch", match);
      if (match && match[1]) {
        // URL decode the value (optional, depending on the encoding)
        const token = decodeURIComponent(match[1]);

        const payload = {
          token: token,
        };
        if (payload.token) {
          dispatch(
            telegramLogin({
              data: payload,
              callBack: async (err, data) => {
                if (parseInt(err?.status_code) === 406) {
                  return navigate("/maintenance");
                }
                if (err) {
                  return;
                }
                if (data) {
                  await ativeGameDetail(data);
                  setTimeout(() => {
                    navigate("/game");
                  }, 3000);
                }
              },
            })
          );
        }
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route
        exact={true}
        path={"/maintenance"}
        element={
          <Maintenance
            sessionLoader={sessionLoader}
            loginLoader={loginLoader}
            gameLoader={gameLoading}
          />
        }
      />
      <Route element={""}>
        <Route index element={<Navigate to="/login" />} />
        {routes.map((data, index) => (
          <Route
            // onUpdate={() => window.scrollTo(0, 0)}
            exact={true}
            path={data.path}
            element={
              <GameEnterRoute
              timeoutDone={timeoutDone.current}
                sessionLoader={sessionLoader}
                loginLoader={loginLoader}
                gameLoader={gameLoading}
              >
                {data.component}
              </GameEnterRoute>
            }
            key={index}
          />
        ))}
      </Route>
      <Route element={<MainLayout />}>
        <Route index element={<Navigate to="/game" />} />
        {privateRoutes.map((data, index) => (
          <Route
            // onUpdate={() => window.scrollTo(0, 0)}
            exact={true}
            path={data.path}
            element={
              <PrivateRoute
              timeoutDone={timeoutDone.current}
                sessionLoader={sessionLoader}
                loginLoader={loginLoader}
                gameLoader={gameLoading}

              >
                {data.component}
              </PrivateRoute>
            }
            key={index}
          />
        ))}
      </Route>
    </Routes>
  );
};

export default App;
