import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import styles from "./claimBonusPop.module.scss";
import congratsImg from "../../../assets/images/congratsPop.png";
import { useSelector } from "react-redux";


const ClaimBonusPop = ({ showClaimBonus, setShowClaimBonus, onFinish }) => {
    const welcomeBonus = useSelector(({ Auth }) => Auth.welcomeBonus);
    const welcomeBonusLoading = useSelector(({ Auth }) => Auth.welcomeBonusLoading);
  
    // Open modal only when welcomeBonus is updated
    useEffect(() => {
      if (welcomeBonus && !welcomeBonusLoading) {
        setShowClaimBonus(true);
      }
    }, [welcomeBonus, welcomeBonusLoading, setShowClaimBonus]);
  
    const handledHide = () => {
      setShowClaimBonus(false);
      onFinish();
    };
  
    if (welcomeBonusLoading) {
      return <p>Loading...</p>;
    }
  
    console.info(welcomeBonus,"BONUSSSS")
    return (
      <Modal show={showClaimBonus} backdrop="true" centered keyboard={false}>
        <Modal.Body>
          <Button onClick={handledHide} variant="transparent" className="border-0 p-0 position-absolute"
            style={{ top: 15, right: 15, zIndex: 999 }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
              <path fillRule="evenodd" clipRule="evenodd"
                d="M13.0024 26.0048C20.1834 26.0048 26.0048 20.1834 26.0048 13.0024C26.0048 5.82137 20.1834 0 13.0024 0C5.82137 0 0 5.82137 0 13.0024C0 20.1834 5.82137 26.0048 13.0024 26.0048ZM13.0062 14.1485L18.1747 19.3251L19.3205 18.1711L14.152 13.0026L19.3205 7.83412L18.1747 6.68827L13.0062 11.8568L7.83767 6.68015L6.6837 7.83412L11.8603 13.0026L6.6837 18.1711L7.83767 19.3251L13.0062 14.1485Z"
                fill="#506380" />
            </svg>
          </Button>
          <div className="text-center">
            <div className="position-relative">
              <img src="https://minehunt-live.s3.us-east-2.amazonaws.com/congratsPop.png" alt="Congrats" className="img-fluid w-100" />
            </div>
            <h3 className="fw-bold my-2" style={{ color: "#3d71d3", fontSize: "26px", textAlign: "center", textShadow: "1px 2px 1px rgb(35 62 114)" }}>
              Welcome Bonus
            </h3>
            <h2 className="m-0 fw-bold">
              <span className="icn">
                <img style={{ height: 60 }} src={welcomeBonus?.coinIcon} alt="Coin" className="img-fluid object-fit-contain" />
              </span>
              <span className="text-white">{welcomeBonus?.amount}</span>
            </h2>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
export default ClaimBonusPop;
