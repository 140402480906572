import React from "react";
import { Button, Modal } from "react-bootstrap";
import styles from "./welcomeBonusPop.module.scss";
import congratsImg from "../../../assets/images/Treasure.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { getWelcomeBonus } from "../../../lib/store/actions";
import LoaderCstm from "../../Loader";
import loader from "../../../assets/images/loading.gif"

const WelcomeBonusPop = ({
  showWelcomeBonus,
  setShowWelcomeBonus,
  showClaimBonus,
  setShowClaimBonus,
  id,
  onFinish,
}) => {
  const dispatch = useDispatch();
  const { welcomeBonusLoading } = useSelector(({ Auth }) => Auth);

  const handledHide = () => {
    setShowWelcomeBonus(!showWelcomeBonus);
    onFinish();
  };
 
  
  
  const handlePopUp = () => {
    dispatch(
      getWelcomeBonus({
        data: { _id: id },
        callBack: (err, res) => {
          if (err) {
            return;
          }
          console.info(res,"RESPONSEEEEEEEEE")
          if(res){
            setShowClaimBonus(true);
            setShowWelcomeBonus(false)
          }
        },
      })
    );
  };

  return (
    <>
      <Modal
        show={showWelcomeBonus}
        // onHide={handledHide}
        backdrop="true"
        centered
        className={`${styles.buyPop} bottomPop`}
        keyboard={false}
      >
        <div className="modalWrpper">
          <Modal.Body>
            <Button
              onClick={handledHide}
              variant="transparent"
              className="border-0 p-0 position-absolute"
              style={{ top: 15, right: 15 }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.0024 26.0048C20.1834 26.0048 26.0048 20.1834 26.0048 13.0024C26.0048 5.82137 20.1834 0 13.0024 0C5.82137 0 0 5.82137 0 13.0024C0 20.1834 5.82137 26.0048 13.0024 26.0048ZM13.0062 14.1485L18.1747 19.3251L19.3205 18.1711L14.152 13.0026L19.3205 7.83412L18.1747 6.68827L13.0062 11.8568L7.83767 6.68015L6.6837 7.83412L11.8603 13.0026L6.6837 18.1711L7.83767 19.3251L13.0062 14.1485Z"
                  fill="#506380"
                />
              </svg>
            </Button>
            <div className="text-center">
              <div className="position-relative">
                <img src={congratsImg} alt="" className="img-fluid w-100" />
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <h3
                  className="fw-bold my-2"
                  style={{
                    color: "#3d71d3",
                    fontSize: "26px",
                    textAlign: "center",
                    textShadow: "1px 2px 1px rgb(35 62 114)",
                    marginTop: "10px",
                  }}
                >
                  {}
                </h3>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-4 gap-10">
                {/* <h2 className={`${styles.price} m-0 fw-bold`}>{}<span className="icn"><img style={{ height: 60 }} src={} alt="" className="img-fluid object-fit-contain" /></span></h2> */}
              </div>
            </div>
            <div className="my-4 text-center">
              <Button
                onClick={() => handlePopUp()}
                style={{ maxWidth: 290 }}
                className="d-inline-flex text-uppercase align-items-center justify-content-center fw-bold commonBtn border-0"
              >
                {welcomeBonusLoading ? 
                <img src={loader} alt="" className="img-fluid object-fit-contain" style={{ height: 20 }} />
                : "Claim"}
              </Button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default WelcomeBonusPop;
